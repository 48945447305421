import React from "react";
import LabLayout from "../../layouts/lab";

const Teams: React.FC = () => {
  return (
    <LabLayout>
      <h1 className="text-3xl">Teams</h1>
      <h3>Recently Accessed</h3>
    </LabLayout>
  );
};

export default Teams;
